<template>
	<div class="text-center">
		<v-dialog
			v-model="showDialog"
			max-width="480"
			origin="top center"
			class="dialog pa-0"
			persistent
			scrollable
		>
			<v-card>

				<v-card-text class="pa-8">
          <v-icon size="58" color="orange">mdi-alert-circle-outline</v-icon>
          <h1 class="header-1 mt-4">{{ $t('disable_vendor') }}</h1>
					{{ $t('disable_vendor_message') }} "{{ formData.name }}"?

          <div class="mt-8">
            <v-btn
              class="mr-2 white--text text-capitalize"
              color="#0171a1"
              depressed
              large
              @click="onDelete"
							:disabled="isDeleting"
							:loading="isDeleting"
            >
              {{ $t('disable') }}
            </v-btn>
            <v-btn
              outlined
              large
              depressed
              class="teal--text text-capitalize"
              @click="onClose"
							:disabled="isDeleting"
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
				</v-card-text>

			</v-card>
			<v-snackbar
				timeout="2000"
				vertical
				:color="snackbarOption.color"
				v-model="showSnackbar"
				bottom
			>
			<v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
			</v-snackbar>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	props: ["open", "formData"],

	data() {
		return {
			isDeleting: false,
			errorMessage: '',
			showSnackbar: false,
			snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
		};
	},

	computed: {
		showDialog: {
			get() {
				return this.open;
			},
		},
	},

	methods: {

		...mapActions('accounting', ['disableVendor']),

		async onDelete() {
			if(this.isDeleting) {
				return;
			}
			this.showSnackbar = false;
			this.isDeleting = true;
			try {

				const { message } = await this.disableVendor(this.formData.id);

				this.isDeleting = false;
				this.$emit("toggle", { disabled: true, message });
			} catch(error) {
				console.log(error);
				
				const { data } = error.response || { data: {  } };

				this.snackbarOption = {
					icon: 'mdi-alert-circle',
					color: 'error',
					message: data.message || "Could not disable the data.",
				};
				this.showSnackbar = true;
				this.isDeleting = false;
			}
		},

		onClose() {
			this.$emit("toggle");
		},

	},
};
</script>

<style lang="scss" scoped>
	.dialog {
		z-index: 9999;
	}
</style>