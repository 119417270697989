<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">{{ $t('vendors') }}</p>
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-container fluid>
					<v-data-table
            :headers="tableHeader"
            :items="tableData"
            class="elevation-1 v-table-middle-align"
            :single-select="false"
            show-select
            v-model="selectedVendors"
            :loading="isVendorsDataLoading"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:top>
              <div class="d-md-flex w-100 pt-4 px-4 pb-3 border-bottom">
                <div class="d-md-flex justify-md-end my-2 my-md-0 mx-md-0">
                  <div class="d-flex">
                    <v-menu offset-y :close-on-content-click="false" v-model="showFilter">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" outlined large height="40" class="text-capitalize" color="primary">
                          {{ $t('filters') }}
                          <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card width="300" class="pt-2">
                        <v-card-text>
                          <label for="filter-enabled" class="primary--text">{{ $t('status') }}</label>
                          <v-select
                            id="filter-enabled"
                            v-model="isEnabled"
                            solo
                            :items="['Active', 'In-Active']"
                            flat
                            outlined
                            dense
                            color="primary"
                            hide-details
                          >                              
                          </v-select>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            color="primary"
                            text
                            link
                            @click="fetchVendorsData"
                          >
                            {{ $t('ok') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                    <div class="search-field-wrapper ml-2">
                      <v-text-field
                        v-model="searchText"
                        :label="$t('search_vendors')"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        hide-details
                        flat
                        solo
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="d-md-block d-none">
                    <v-btn
                      class="form-border text-capitalize mx-2"
                      color="primary"
                      @click="onToggleVendorForm"
                      height="38"
                    >
                      {{ $t('add_vendor') }}
                    </v-btn>
                  </div>
                  <div class="d-sm-block d-md-none mt-2">
                    <v-btn
                      class="form-border text-capitalize"
                      color="primary"
                      @click="onToggleVendorForm"
                      height="38"
                      width="100%"
                    >
                      {{ $t('add_vendor') }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
                <v-btn text small rounded outlined :color="item.enabled ? 'success': 'error'" class="pa-0" >
                  <div v-if="item.enabled" class="d-flex align-center justify-space-around pa-2" style="min-width: 60px">
                    {{ $t('yes') }}
                    <v-icon>mdi-check-circle</v-icon>
                  </div>
                  <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                    <v-icon>mdi-minus-circle</v-icon>
                    {{ $t('no') }}
                  </div>
                </v-btn>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h6
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.email"
                  />
                </template>
                <span>{{ item.phone }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    @click="onSelectEditVendorData(item)"
                  >
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onSelectVendorInformation(item)"
                  >
                    <v-list-item-title>{{ $t('view') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onSelectVendorToDisable(item)"
                  >
                    <v-list-item-title class="red--text">{{ $t('disable') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-alert>
                <span class="subtitle-2">{{ $t('no_data') }}</span>
                <br />
                <v-btn outlined text small @click="onToggleVendorForm" color="#0889a0" class="mb-2 font-weight-bold py-4 text-capitalize">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('add_vendor') }}
                </v-btn>
              </v-alert>
            </template>
            <template v-slot:foot>
              <tfoot>
                <tr>
                  <td colspan="7" class="text-right">
                    <div class="d-flex justify-center justify-sm-end">
                      <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                      <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{ (pagination.total || 0).toLocaleString() }} <span class="">{{ $t('records') }}</span></h6>
                      <v-spacer/>
                      <div class="select-page-limit">
                        <v-select
                          v-model="pageLimit"
                          :items="[10, 20, 25]"
                          label="Per page"
                          hide-details
                          dense
                          flat
                          solo
                          @change="onChangePageLimit"
                        ></v-select>
                      </div>
                      <pagination
                        :total="pagination.total_pages || 1"
                        :current-page="currentPage"
                        :total-visible="10"
                        @pageSelected="onPaginationClick"
                      >
                      </pagination>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-data-table>
          <v-snackbar
            timeout="2000"
            vertical
            :color="snackbarOption.color"
            v-model="showSnackbar"
            bottom
          >
            <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
          </v-snackbar>
          <vendor-form :open="showFormDialog" :is-edit-mode="isEditMode" :form-values="selectedVendorData" @toggle="onToggleVendorForm"></vendor-form>
          <vendor-disable :open="showDisableModal" :form-data="selectedDataToDisable" @toggle="onToggleDisableVendorForm"></vendor-disable>

          <v-dialog
            v-model="showInfoModal"
            max-width="980"
            origin="top center"
            class="dialog pa-0"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title class="pa-0">
                <v-toolbar light elevation="1" class="">
                  <v-toolbar-title>{{ selectedVendorInformation.name }}</v-toolbar-title>
                  <v-spacer></v-spacer>

                  <v-btn class="d-none d-sm-flex" color="primary" @click="onSelectEditVendorData(selectedVendorInformation)">
                    {{  $t('Edit') }}
                  </v-btn>
                  <v-btn text outlined class="ml-1 mr-4 d-none d-sm-flex" @click="onSelectVendorToDisable(selectedVendorInformation)">
                    {{ $t('disable') }}                    
                  </v-btn>
                  <v-btn icon class="d-none d-sm-flex" @click="onToggleVendorInformationModal">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-card-title>
              <v-card-text class="py-4 pb-8">
                <v-row>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('email') }}</h6>
                    <span>{{ selectedVendorInformation.email }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('phone') }}</h6>
                    <span>{{ selectedVendorInformation.phone }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('currencyname') }}</h6>
                    <span>{{ currencyValue }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('website') }}</h6>
                    <span>{{ selectedVendorInformation.website }}</span>
                  </v-col>                  
                </v-row>
                <v-row>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('reference') }}</h6>
                    <span>{{ selectedVendorInformation.reference }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('tax_number') }}</h6>
                    <span>{{ selectedVendorInformation.tax_number }}</span>
                  </v-col>
                  <v-col md="6" lg="6">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('address') }}</h6>
                    <span>{{ selectedVendorInformation.address }}</span>
                  </v-col>            
                </v-row>
                <v-row>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('address_City') }}</h6>
                    <span>{{ selectedVendorInformation.city }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('state') }}</h6>
                    <span>{{ selectedVendorInformation.state }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('zip_code') }}</h6>
                    <span>{{ selectedVendorInformation.zip_code }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('country') }}</h6>
                    <span>{{ selectedVendorInformation.country }}</span>
                  </v-col>                  
                </v-row>
              </v-card-text>
              <v-divider class="d-flex d-sm-none"/>
              <v-card-actions class="d-flex d-sm-none">
                <v-btn color="primary" @click="onSelectEditVendorData(selectedVendorInformation)">
                    {{  $t('Edit') }}
                  </v-btn>
                  <v-btn text outlined class="ml-1 mr-4" @click="onSelectVendorToDisable(selectedVendorInformation)">
                    {{ $t('disable') }}                    
                  </v-btn>
                  <v-spacer/>
                  <v-btn @click="onToggleVendorInformationModal" elevation="0">
                    close
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

				</v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import VendorForm from '../components/VendorForm.vue';
import VendorDisable from '../components/VendorDisableModal.vue';
import Pagination from '../components/Pagination.vue';
import { apiErrorMessage, debounce } from '@/utils/general';

export default {
  components: {
    VendorForm,
    VendorDisable,
    Pagination,
  },

  data() {
    return {
      showInfoModal: false,
      isVendorTogglingProgress: false,
      isVendorsDataLoading: false,
      vendorLists: [],
      page: 1,
      showDisableModal: false,
      selectedVendorData: {},
      selectedVendorInformation: {},
      selectedDataToDisable: {},
      isEditMode: false,
      searchText: '',
      selectedVendors: [],
      showFormDialog: false,
      showSnackbar: false,
			snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
      currentPage: 1,
      pageLimit: 10,
      showFilter: false,
      isEnabled: 'Active',
    };
  },

  created() {
    this.fetchVendorsData();
  },

  computed: {
    // ...mapState('accounting', ['vendorLists']),
    tableData: {
      get(){
        return this.vendorLists?.data || []; 
      }
    },

    tableHeader() {
      return [
        {
          text: this.$t('name'),
          value: "name",
          class: "text-uppercase th--text font-weight-bold"
        },
        // {
        //   text: this.$t('phone'),
        //   value: "phone",
        //   class: "text-uppercase th--text font-weight-bold"
        // },
        {
          text: this.$t('address'),
          value: "address",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('email'),
          value: "email",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('enabled'),
          value: "enabled",
          class: "text-uppercase th--text font-weight-bold"
        },
        { text: "", value: "actions", sortable: false },
      ];
    },

    pagination: {
      get() {
        return this.vendorLists.data ? this.vendorLists.meta.pagination : {};
      }
    },

    currencyValue: {
      get() {
        return this.selectedVendorInformation?.currency_values ? JSON.parse(this.selectedVendorInformation.currency_values).name : '';
      }
    }
  },

  watch: {
    searchText: debounce(function() {
      this.fetchVendorsData();
    }, 300)
  },

  methods: {
    ...mapActions('accounting', ['getVendorsData']),

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchVendorsData()
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchVendorsData();
    },

    toggleVendorIsEnable(){
      this.isVendorTogglingProgress = true
      // this.isVendorTogglingProgress = false
    },

    async fetchVendorsData() {
      this.showFilter = false;
      if(this.isVendorsDataLoading) {
        return;
      }
      this.isVendorsDataLoading = true;
      try {
        const data = await this.getVendorsData({
          page: this.currentPage,
          limit: this.pageLimit,
          search: this.searchText || '',
          enabled: this.isEnabled === 'Active' ? 1 : 0,
        });
        this.vendorLists = data; 
        this.isVendorsDataLoading = false;
      } catch(error) {
        this.isVendorsDataLoading = false;
        apiErrorMessage(error);
      }
    },

    onToggleVendorForm(options = {}) {
      this.isEditMode = false;
      this.selectedServiceData = {};
      this.showFormDialog = !this.showFormDialog;
      if(options.created || options.updated) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'success',
					message: options.message,
				};
				this.showSnackbar = true;
        this.showInfoModal = false;
        this.fetchVendorsData();
      }
    },

    onSelectEditVendorData(data = {}) {
      this.selectedVendorData = JSON.parse(JSON.stringify(data));
      this.isEditMode = true;
      this.showFormDialog = true;
    },

    onSelectVendorToDisable(data = {}) {
      this.selectedDataToDisable = JSON.parse(JSON.stringify(data));
      this.onToggleDisableVendorForm();
    },

    onToggleDisableVendorForm(options = {}) {
      this.showDisableModal = !this.showDisableModal;

      if(options.disabled) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'red',
					message: options.message || 'Disabled.',
				};
				this.showSnackbar = true;
        this.showInfoModal = false;

        this.fetchVendorsData();
      }
    },

    onSelectVendorInformation(data) {
      this.selectedVendorInformation = data;
      this.onToggleVendorInformationModal();
    },

    onToggleVendorInformationModal() {
      this.showInfoModal = !this.showInfoModal;
      if(!this.showInfoModal) {
        this.selectedVendorInformation = {};
      }
    }
    
  }
};
</script>


<style lang="scss" scoped>
  .vue-app {
    background: none;
  }

  .v-tabs {
    border-bottom: 1px solid #ddd;

    .v-tab {
      color: #0889a0;
    }
  }

  .container {
    background-color: none;
  }

  .w-100 {
    width: 100%;
  }

  .v-list-item {
    min-height: 36px;
  }
  
  .border-bottom {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
</style>
<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
	.dialog {
		z-index: 999;
	}
  .select-page-limit {
    width: 80px;
  }
</style>
